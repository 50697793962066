<template>
  <div class="login_box">
    <div class="login_form">
      <div class="content">
        <div class="logo_img">
          <img src="../assets/ico.png" alt="" />
        </div>
        <div class="msg">热点管理平台</div>
        <div class="username">
          <input
            type="text"
            placeholder="请输入用户名"
            v-model="name_user"
            maxlength="24"
          />
        </div>
        <div class="password">
          <input
            :type="isPlainText ? 'text' : 'password'"
            placeholder="请输入密码"
            v-model="pwd"
            maxlength="16"
            @keyup.enter="login"
          />
          <div class="eye_box" @click="isPlainText = !isPlainText">
            <img v-if="!isPlainText" src="../assets/eye_close.png" alt="" />
            <img v-else src="../assets/eye_open.png" alt="" />
          </div>
        </div>

        <div class="btn" @click="login">登录</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Login } from '@/api'
import MD5 from 'md5'

export default {
  data() {
    return {
      // 是否是明文
      isPlainText: false,
      // 登录表单
      name_user: '',
      pwd: ''
    }
  },
  methods: {
    // 登录
    async login() {
      const nameUser = this.name_user.trim()
      const pwd = this.pwd.trim()
      if (!nameUser.length) {
        return this.$message.warning('请输入用户名')
      }
      if (!pwd.length) {
        return this.$message.warning('请输入密码')
      }
      if (pwd.length < 6 || pwd.length > 16) {
        return this.$message.warning('密码长度为 6 ~ 16 位')
      }
      const params = {
        name_user: nameUser,
        pwd: MD5(pwd)
      }
      const { ret, data, msg } = await Login(params)
      if (ret !== 0) {
        this.$message.error('用户名或者密码错误')
        setTimeout(() => {
          window.location.reload()
        }, 500)
        return
      }
      this.$store.dispatch('user/saveToken', data.token)
      this.$router.push('/welcome')
    }
  }
}
</script>

<style lang='scss' scoped>
.login_box {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: url(../assets/login_bg3.png) no-repeat;
  background-size: cover;

  .login_form {
    width: 487px;
    height: 596px;
    border-radius: 12px;
    position: absolute;
    right: 12%;
    top: calc(50vh - 297px);
    background: inherit;
    overflow: hidden;
    // filter: blur(0.3px);
    border-top: 1px solid #fff;

    &::before {
      content: '';
      position: absolute;
      top: -10px;
      right: -10px;
      bottom: -10px;
      left: -10px;
      z-index: 1;
      background: inherit;
      background-attachment: fixed;
      filter: blur(4px);
    }

    &::after {
      content: '';
      position: absolute;
      top: -10px;
      right: -10px;
      bottom: -10px;
      left: -10px;
      z-index: 2;
      background: rgba(255, 255, 255, 0.25);
    }

    .content {
      position: absolute;
      z-index: 3;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      .logo_img {
        width: 66px;
        height: 66px;
        margin: 82px auto 0;

        img {
          width: inherit;
          height: inherit;
        }
      }

      .msg {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        margin-top: 16px;
      }

      .username {
        margin: 46px auto 0;
        width: 344px;
        height: 56px;

        input {
          width: 100%;
          height: 100%;
          background: #f5f5f5;
          border-radius: 8px;
          box-sizing: border-box;
          border: none;
          outline: none;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          // color: #cccccc;
          padding-left: 24px;
          color: #333;

          &::placeholder {
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #cccccc;
          }
        }
      }

      .password {
        position: relative;
        margin: 24px auto 0;
        width: 344px;
        height: 56px;

        input {
          width: 100%;
          height: 100%;
          background: #f5f5f5;
          border-radius: 8px;
          box-sizing: border-box;
          border: none;
          outline: none;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          padding-left: 24px;
          color: #333;

          &::placeholder {
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #cccccc;
          }
        }

        .eye_box {
          position: absolute;
          right: 24px;
          top: 50%;
          transform: translateY(-50%);
          width: 24px;
          height: 24px;

          img {
            width: 24px;
            height: 24px;
            cursor: pointer;
          }
        }
      }

      .btn {
        width: 344px;
        height: 57px;
        background: #2a47b0;
        border-radius: 8px;
        margin: 48px auto 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        cursor: pointer;

        &:hover {
          background-color: rgba(42, 71, 176, 0.86);
        }
      }
    }
  }
}
</style>
